<template>
  <div>
    <FormWizard
      :form-steps-name="steps"
      :schema-array="schemas"
      :init-form="initForm"
      :init-step="initStep"
      :has-save-draft="true"
      :submit-step-index="4"
      finish-text="回申請案件總表"
      @submit-form="submit"
      @save-draft="saveDraft"
      @finish="finish"
    >
      <template v-slot="{ currentFormData, setValues }">
        <FormWizardStep :step="0">
          <div class="panel">
            <ul class="dotList">
              <li>
                在您填寫申請表之前，請先確認是否備妥以下資料，以利填寫表單：
                <ul>
                  <li>― 活動企劃書</li>
                  <li>― 申請單位簡介(含相關實績)</li>
                </ul>
              </li>
              <li>申請活動不得涉及總統、副總統、中央及地方公職人員選舉競選活動及政黨黨務活動。</li>
              <li>倘實際辦理活動與申請活動內容不符，本場館得要求立即終止活動並沒入已收之款項。</li>
              <li>本場館保有場地變更及出借決定權。</li>
            </ul>
          </div>
          <div class="form-item">
            <Field v-slot="{ handleChange, value, errors }" name="acceptTerm" type="checkbox">
              <div class="form-checkbox" :class="{'is-invalid': errors.length > 0}">
                <label for="acceptTerm">
                  <input
                    id="acceptTerm"
                    type="checkbox"
                    name="acceptTerm"
                    @change="handleChange(!value)"
                    :checked="value"
                  />
                  <span class="form-checkbox-box"></span>
                  我已詳閱並同意上述注意事項
                </label>
              </div>
            </Field>
            <div class="form-promp is-invalid">
              <ErrorMessage name="acceptTerm" />
            </div>
          </div>
        </FormWizardStep>

        <FormWizardStep :step="1">
          <div class="grid-row">
            <div class="col-12_md-6">
              <div class="form-item">
                <label for="" class="form-label">會員名稱</label>
                <input type="text" class="form-input" readonly :value="userName" />
              </div>
            </div>
            <div class="col-12_md-6"></div>
            <div class="col-12_md-6">
              <FormInput field-name="activityName" field-label="活動名稱" />
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="activityNameEN" field-label="活動英文名稱" :is-required="false" />
            </div>
            <div class="col-12_md-6">
              <FormRadio
                field-label="節目來源"
                field-name="activitySource"
                :options="formOption.activitySource"
              />
            </div>
            <div class="col-12_md-6">
              <FormRadio
                field-name="activityType"
                field-label="活動型式"
                :options="formOption.activityType"
              />
            </div>
            <div class="col-12">
              <FormRadio
                field-label="租用範圍"
                field-name="activityArea"
                :options="formOption.activityArea"
                @option-selected="() => (
                  setValues({
                    totalStartDate1: null,
                    totalEndDate1: null,
                    showStartDate1: null,
                    showEndDate1: null,
                    showDayCount1: null,
                    totalStartDate2: null,
                    totalEndDate2: null,
                    showStartDate2: null,
                    showEndDate2: null,
                    showDayCount2: null,
                  })
                )"
              />
            </div>
            <div class="col-12_md-6">
              <FormRadio
                field-label="售票"
                field-name="activityCharge"
                :options="formOption.activityCharge"
              />
            </div>
            <div class="col-12_md-6">
              <FormNumber field-name="activityPeople" field-label="預估總參觀人次" />
            </div>
          </div>

          <hr class="form-hr" />

          <div class="form-title">選擇檔期時段</div>
          <div class="form-promp is-notify">申請檔期請包含活動進撤場且日期必須相連</div>
          <div class="grid-row">
            <div class="col-12_md-4">
              <DateRange
                form-label="第一意願檔期時段"
                field-start-name="totalStartDate1"
                field-end-name="totalEndDate1"
                :min-date="$moment().format('YYYY-MM-DD')"
                :fetch-event-by-site-id="2"
                :fetch-area="currentFormData.activityArea"
                @range-change="setValues({ showStartDate1: null, showEndDate1: null })"
              />
            </div>
            <div class="col-12_md-4">
              <DateRange
                form-label="第一意願展演時段"
                field-start-name="showStartDate1"
                field-end-name="showEndDate1"
                :min-date="currentFormData.totalStartDate1"
                :max-date="currentFormData.totalEndDate1"
                :fetch-event-by-site-id="2"
                :fetch-area="currentFormData.activityArea"
                @range-change="({duration}) => setValues({ showDayCount1: duration })"
              />
            </div>
            <div class="col-12_md-4">
              <FormInput field-name="showDayCount1" field-label="第一意願總展演天數" :is-readonly="true"></FormInput>
            </div>
          </div>
          <div class="grid-row">
            <div class="col-12_md-4">
              <DateRange
                form-label="第二意願檔期時段"
                field-start-name="totalStartDate2"
                field-end-name="totalEndDate2"
                :min-date="$moment().format('YYYY-MM-DD')"
                :fetch-event-by-site-id="2"
                :fetch-area="currentFormData.activityArea"
                :is-required="false"
                @range-change="setValues({ showStartDate2: null, showEndDate2: null })"
              />
            </div>
            <div class="col-12_md-4">
              <DateRange
                form-label="第二意願展演時段"
                field-start-name="showStartDate2"
                field-end-name="showEndDate2"
                :min-date="currentFormData.totalStartDate2"
                :max-date="currentFormData.totalEndDate2"
                :fetch-event-by-site-id="2"
                :fetch-area="currentFormData.activityArea"
                :is-required="false"
                @range-change="({duration}) => setValues({ showDayCount2: duration })"
              />
            </div>
            <div class="col-12_md-4">
              <FormInput field-name="showDayCount2" field-label="第二意願總展演天數" :is-required="false" :is-readonly="true"></FormInput>
            </div>
          </div>
        </FormWizardStep>

        <FormWizardStep :step="2">
          <div class="form-promp is-notify">限PDF格式，各檔案不得超過20MB</div>
          <div class="grid-row">
            <div class="col-12_md-6">
              <FileInput
                field-label="活動企劃書"
                field-name="file1"
                :limit-size="20"
                :limit-type="['jpg', 'jpeg', 'pdf']"
              />
            </div>
            <div class="col-12_md-6">
              <FileInput
                field-label="申請單位簡介(含相關實績)"
                field-name="file2"
                :limit-size="20"
                :limit-type="['jpg', 'jpeg', 'pdf']"
              />
            </div>
          </div>
        </FormWizardStep>

        <FormWizardStep :step="3">
          <div class="form-title" style="display: flex; justify-content: space-between; align-items: center;">主要聯絡人
            <button type="button" class="btn btn-sm btn-primary" @click="setValues(userDataForForm)">帶入會員資料</button>
          </div>
          <div class="grid-row">
            <div class="col-12_md-6">
              <FormInput field-name="contactName" field-label="姓名" />
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="contactEmail" field-label="Email" field-type="email" />
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="contactTel" field-label="電話" field-type="tel" />
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="contactMobile" field-label="手機" />
            </div>
            <div class="col-12">
              <FormInput field-label="聯絡地址" field-name="address" />
            </div>
          </div>

          <hr class="form-hr" />

          <div class="form-title">其他聯絡人</div>
          <div class="grid-row">
            <div class="col-12_md-6">
              <FormInput field-name="otherName" field-label="姓名" :is-required="false"/>
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="otherEmail" field-label="Email" field-type="email" :is-required="false"/>
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="otherTel" field-label="電話" field-type="tel" :is-required="false"/>
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="otherMobile" field-label="手機" :is-required="false"/>
            </div>
          </div>
        </FormWizardStep>

        <FormWizardStep :step="4">
          <template v-if="rentEstimate">
            <div class="swipeTable-wrapper">
              <table class="table table-v-center table-center">
                <tbody>
                  <tr>
                    <th class="fixed-th" width="20%">志願序</th>
                    <td>第一意願</td>
                    <td>第二意願</td>
                  </tr>
                  <tr>
                    <th class="fixed-th">申請檔期</th>
                    <td>
                      <template v-if="currentFormData.totalStartDate1 && currentFormData.totalEndDate1">
                        {{currentFormData.totalStartDate1}} 至 {{currentFormData.totalEndDate1}}，
                        共{{dateDuration(currentFormData.totalStartDate1, currentFormData.totalEndDate1)}}日
                      </template>
                      <template v-else>-</template>
                    </td>
                    <td>
                      <template v-if="currentFormData.totalStartDate2 && currentFormData.totalEndDate2">
                        {{currentFormData.totalStartDate2}} 至 {{currentFormData.totalEndDate2}}，
                        共{{dateDuration(currentFormData.totalStartDate2, currentFormData.totalEndDate2)}}日
                      </template>
                      <template v-else>-</template>
                    </td>
                  </tr>
                  <tr>
                    <th class="fixed-th">展演日</th>
                    <td>
                      <template v-if="currentFormData.showStartDate1 && currentFormData.showEndDate1">
                        {{currentFormData.showStartDate1}} 至 {{currentFormData.showEndDate1}}，
                        共{{currentFormData.showDayCount1}}日
                      </template>
                      <template v-else>-</template>
                    </td>
                    <td>
                      <template v-if="currentFormData.showStartDate2 && currentFormData.showEndDate2">
                        {{currentFormData.showStartDate2}} 至 {{currentFormData.showEndDate2}}，
                        共{{currentFormData.showDayCount2}}日
                      </template>
                      <template v-else>-</template>
                    </td>
                  </tr>
                  <tr>
                    <th class="fixed-th">進撤場日場地租借費用</th>
                    <td>{{rentEstimate[0].entryExitsFee }}元</td>
                    <td>{{ rentEstimate[1].entryExitsFee }}元</td>
                  </tr>
                  <tr>
                    <th class="fixed-th">展演日場地租借費用</th>
                    <td>{{ rentEstimate[0].showDayFee }}元</td>
                    <td>{{ rentEstimate[1].showDayFee }}元</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="subtotal">
                    <th class="fixed-th">合計</th>
                    <td>{{rentEstimate[0].totalFee}}元</td>
                    <td>{{rentEstimate[1].totalFee}}元</td>
                  </tr>
                  <tr class="guarantee">
                    <th class="fixed-th">保證金</th>
                    <td>{{rentEstimate[0].guaranteeFee}}元</td>
                    <td>{{rentEstimate[1].guaranteeFee}}元</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="panel">
              此費用僅供參考，尚不包含電費、管理費及其他銷售營業額等相關衍生費用，亦不包含長租、產業優惠，實際費用以簽約金額為準。
            </div>
          </template>
          <template v-else>
            <div class="panel">您的選項不提供試算</div>
          </template>
        </FormWizardStep>

        <FormWizardStep :step="5">
          <div class="text-center pt-5 pb-5">
            <Vue3Lottie :animationData="FinishJSON" width="150px" :loop="false" />
            <div class="h3">謝謝您!您已經完成文化館檔期申請!</div>
            <div class="h3">您的案件編號：{{ finishNumNo }}</div>
            <p>如欲追蹤案件申請進度，可至各申請單位的「申請案件總表」查詢</p>
          </div>
        </FormWizardStep>
      </template>
    </FormWizard>
  </div>
</template>
<script>
import { mapActions, mapState, mapWritableState } from 'pinia';
import { useUserData } from '@/store/user';
import { useFormStore } from '@/store/form';
import { culturalRent as schemas } from '@/utils/schema';
import { Vue3Lottie } from 'vue3-lottie';
import FinishJSON from '@/assets/images/finish.json';
import { Field, ErrorMessage } from 'vee-validate';
import FormWizard from '@/components/FormWizard.vue';
import FormWizardStep from '@/components/FormWizardStep.vue';
import FormInput from '@/components/Input/FormInput.vue';
import FormNumber from '@/components/Input/FormNumber.vue';
import FormRadio from '@/components/Input/FormRadio.vue';
import DateRange from '@/components/Input/DateRange.vue';
import FileInput from '@/components/Input/FileInput.vue';

export default {
  components: {
    FormWizard,
    FormWizardStep,
    FormInput,
    FormNumber,
    FileInput,
    FormRadio,
    DateRange,
    Field,
    ErrorMessage,
    Vue3Lottie,
  },
  data() {
    return {
      steps: ['申請須知', '案件資訊', '文件上傳', '聯絡人', '費用試算', '完成申請'],
      schemas,
      initForm: {
        acceptTerm: false,
        activitySource: '1',
        activityType: '1',
        activityArea: '1',
        activityCharge: '1',
      },
      initStep: 0,
      FinishJSON,
      finishNumNo: '',
      disabledDates: [],
    };
  },
  methods: {
    fetchDisableDates() {
      this.disabledDates = [];
    },
    async submit(formData, nextCallback) {
      const { submitResult, resultMessage } = await this.submitEventForm();
      if (submitResult === null) {
        alert(resultMessage);
      } else {
        this.finishNumNo = submitResult.numNo;
        nextCallback();
      }
    },
    async saveDraft() {
      const { submitResult, resultMessage } = await this.submitEventForm(true);
      if (submitResult === null) {
        alert(resultMessage);
      } else {
        this.finish();
      }
    },
    finish() {
      this.$router.push('/user/project');
    },
    ...mapActions(useFormStore, ['submitEventForm', 'resetForm']),
  },
  computed: {
    ...mapState(useUserData, ['userDataForForm', 'userName']),
    ...mapState(useFormStore, {
      formDataStore: 'formData',
      formOption: (store) => store.formOption.cultural,
      rentEstimate: 'rentEstimate',
      isDraft: 'isDraft',
    }),
    ...mapWritableState(useFormStore, ['siteId']),
  },
  created() {
    this.siteId = 2;
    // 判斷是否要載入 store中的 formData
    if (this.isDraft) this.initForm = this.formDataStore;
  },
  unmounted() {
    this.resetForm();
  },
};
</script>
