<template>
  <div class="form-item">
    <label class="form-label" :class="{ 'is-required': isRequired }" :for="fieldName">
      {{ fieldLabel }}
      <slot name="label" />
      <Popper
        class="tooltip"
        v-if="fieldHelper"
        :content="fieldHelper"
        arrow
        :hover="true"
        placement="right"
      >
        <Icon name="Info"></Icon>
      </Popper>
    </label>
    <div class="form-group">
      <Field :name="fieldName" v-slot="{ value, field, handleChange, errors }">
        <input
          type="text"
          class="form-input"
          :class="{'is-invalid': errors.length > 0 }"
          v-bind="field"
          :value="formatter(value)"
          @input="handler($event, handleChange)"
          @change="handler($event, handleChange)"
        >
      </Field>
    </div>
    <div class="form-promp is-notify">
      <slot name="notify" />
    </div>
    <div class="form-promp is-invalid">
      <ErrorMessage :name="fieldName" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue';
import { Field, ErrorMessage } from 'vee-validate';

export default {
  props: {
    fieldName: String,
    fieldLabel: String,
    fieldHelper: {
      type: String,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Field,
    ErrorMessage,
    Icon,
  },
  methods: {
    formatter(val) {
      return val ? val.toLocaleString('zh-Hant') : val;
    },
    handler(e, handleChange) {
      const val = `${e.target.value}`.replaceAll(',', '').replaceAll(/\D/g, '');
      handleChange(Number(val));
    },
  },
};
</script>
